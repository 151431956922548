import TYPES from '@/types';

// Application
import {
  SearchIncomesQuery,
} from '@/modules/my-investment/catalogs/kuspit/income/application/queries';
import {
  SearchInvestmentsQuery,
} from '@/modules/my-investment/catalogs/kuspit/investment/application/queries';
import {
  SearchOperationsQuery,
} from '@/modules/my-investment/catalogs/kuspit/operation/application/queries';
import {
  SearchSourcesQuery,
} from '@/modules/my-investment/catalogs/kuspit/source/application/queries';
import {
  SearchOriginsQuery,
} from '@/modules/my-investment/catalogs/kuspit/origin/application/queries';
import SearchAgreementsAsyncQuery
  from '@/modules/my-investment/agreements/application/queries/search-agreements-async-query';
import {
  SearchOnBoardingStepsQuery,
} from '@/modules/my-investment/on-boarding-steps/application/queries';
import {
  SearchInvestmentProvidersQuery,
} from '@/modules/my-investment/investment-provider/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';
import GetPersonQueryService
  from '@/modules/my-investment/person/application/queries/get-my-investment-person-query';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import {
  IncomeEntity,
} from '@/modules/my-investment/catalogs/kuspit/income/domain/entities/income-entity';
import {
  InvestmentEntity,
} from '@/modules/my-investment/catalogs/kuspit/investment/domain/entities/investment-entity';
import {
  OperationEntity,
} from '@/modules/my-investment/catalogs/kuspit/operation/domain/entities/operation-entity';
import {
  SourceEntity,
} from '@/modules/my-investment/catalogs/kuspit/source/domain/entities/source-entity';
import {
  OriginEntity,
} from '@/modules/my-investment/catalogs/kuspit/origin/domain/entities/origin-entity';
import {
  AgreementEntity,
} from '@/modules/my-investment/agreements/domain/entities/agreement-entity';
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import { v4 as uuid } from 'uuid';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import {
  CustomerAgreementEntity,
} from '@/modules/on-boarding/customer-agreements/domain/entities/customer-agreement-entity';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { DatetimeValue } from '@/modules/shared/domain/value-objects/datetime-value';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { requiredRuleByKey } from '@/vue-app/utils/form-rules';

type RadioOption = {
  label: string;
  value: boolean;
}

export default class ContractSavingsAdditionalInformationFinancialInformationViewModel {
  @Inject(TYPES.SEARCH_CATALOG_KUSPIT_INCOME_QUERY)
  readonly searchIncomesQuery!: SearchIncomesQuery;

  @Inject(TYPES.SEARCH_CATALOG_KUSPIT_INVESTMENT_QUERY)
  readonly searchInvestmentsQuery!: SearchInvestmentsQuery;

  @Inject(TYPES.SEARCH_CATALOG_KUSPIT_OPERATION_QUERY)
  readonly SearchOperationsQuery!: SearchOperationsQuery;

  @Inject(TYPES.SEARCH_CATALOG_KUSPIT_SOURCE_QUERY)
  readonly searchSourcesQuery!: SearchSourcesQuery;

  @Inject(TYPES.SEARCH_CATALOG_KUSPIT_ORIGIN_QUERY)
  readonly searchOriginsQuery!: SearchOriginsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_AGREEMENTS_ASYNC_QUERY)
  readonly searchAgreementsAsyncQuery!: SearchAgreementsAsyncQuery;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  readonly searchInvestmentProvidersQuery!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  readonly createOnBoardingStepCommand!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  readonly updateOnBoardingStepCommand!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.GET_INVESTMENT_PERSON_QUERY)
  private readonly getPersonQuery!: GetPersonQueryService;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  @Inject(TYPES.DATETIME_VALUE)
  private datetime_value!: DatetimeValue;

  i18n_namespace = 'components.contract-savings.additional-information.financial-information';

  small_screen: null | boolean = null;

  confirm_information = false;

  investment_provider_name = 'kuspit';

  exists_step = false;

  step_name = 'on_boarding_kuspit_financial_information';

  investment_provider_id = '';

  content_format_to_send = {
    id: '',
    description: '',
  }

  on_boarding_step: OnBoardingStepEntity = {
    id: uuid(),
    current_step: '',
    investment_provider_id: '',
    payload: {
      monthly_income: this.content_format_to_send,
      monthly_investment: this.content_format_to_send,
      operations_number: this.content_format_to_send,
      source_of_income: this.content_format_to_send,
      source_of_resources: this.content_format_to_send,
    },
  };

  inputs = {
    monthly_income: {
      id: '',
      descripcion: '',
    },
    monthly_investment: {
      id: '',
      descripcion: '',
    },
    operations_per_month: {
      id: '',
      descripcion: '',
    },
    income_source: {
      id: '',
      descripcion: '',
    },
    resources_origin: {
      id: '',
      descripcion: '',
    },
  };

  agreements_list: Array<AgreementEntity> = [];

  user_agreements: Array<CustomerAgreementEntity> = [];

  incomes: Array<IncomeEntity> = [];

  investments: Array<InvestmentEntity> = [];

  operations: Array<OperationEntity> = [];

  sources: Array<SourceEntity> = [];

  origins: Array<OriginEntity> = [];

  agreements: Array<AgreementEntity> = [];

  steps: Array<OnBoardingStepEntity> = [];

  is_loading = true;

  rules = {
    select: [(value: any) => requiredRuleByKey(value, 'id')],
  }

  form_validity = false;

  are_deposits_greater_than_21k = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  radio_options: Array<RadioOption> = [
    {
      label: this.translate('yes'),
      value: true,
    },
    {
      label: this.translate('no'),
      value: false,
    },
  ];

  get can_continue() {
    return this.confirm_information && !this.is_loading && this.form_validity;
  }

  get userName() {
    return this.getPersonQuery.execute().name;
  }

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  verifyStep = async (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === this.step_name,
    );
    if (search_step) {
      this.exists_step = true;
      this.on_boarding_step = search_step;
      this.setInputsDataFromStep();
    }
  };

  setInputsDataFromStep = () => {
    this.inputs.monthly_income = {
      id: this.on_boarding_step.payload.monthly_income.id,
      descripcion: this.on_boarding_step.payload.monthly_income.description,
    };
    this.inputs.monthly_investment = {
      id: this.on_boarding_step.payload.monthly_investment.id,
      descripcion: this.on_boarding_step.payload.monthly_investment.description,
    };
    this.inputs.operations_per_month = {
      id: this.on_boarding_step.payload.operations_number.id,
      descripcion: this.on_boarding_step.payload.operations_number.description,
    };
    this.inputs.income_source = {
      id: this.on_boarding_step.payload.source_of_income.id,
      descripcion: this.on_boarding_step.payload.source_of_income.description,
    };
    this.inputs.resources_origin = {
      id: this.on_boarding_step.payload.source_of_resources.id,
      descripcion: this.on_boarding_step.payload.source_of_resources.description,
    };
    this.confirm_information = this.on_boarding_step.payload.agreements.length > 0;
    this.are_deposits_greater_than_21k = this.on_boarding_step.payload.deposits_greater_than_21k;
  }

  createAgreement(agreement_name: string, agreements_list: Array<AgreementEntity>) {
    const agreement = agreements_list.find(
      (item) => item.name === agreement_name,
    );
    if (agreement) {
      this.user_agreements.push({
        id: uuid(),
        agreement_type_id: agreement.id,
        accepted_on: null,
        description: agreement.description,
        was_accepted: false,
      });
    }
  }

  setUserAgreements = async () => {
    this.agreements_list = await this.searchAgreementsAsyncQuery.execute();
    if (!this.on_boarding_step.payload.agreements
      || !this.on_boarding_step.payload.agreements.length) {
      this.createAgreement('confirm_bank_information_kuspit', this.agreements_list);
    } else {
      this.user_agreements = this.on_boarding_step.payload.agreements;
    }
  };

  handleAgreementChange(agreement_name: string, confirmed: boolean) {
    const agreement = this.agreements_list.find(
      (item) => item.name === agreement_name,
    );

    if (agreement) {
      const user_agreement_index = this.user_agreements.findIndex(
        (item) => item.agreement_type_id === agreement.id,
      );

      if (user_agreement_index >= 0) {
        // eslint-disable-next-line max-len
        this.user_agreements[user_agreement_index].accepted_on = confirmed ? this.datetime_value.create() : null;
        this.user_agreements[user_agreement_index].was_accepted = confirmed;
      }
    }
  }

  async saveStep() {
    this.is_loading = true;
    try {
      this.on_boarding_step.current_step = this.step_name;
      this.on_boarding_step.investment_provider_id = this.investment_provider_id;
      this.on_boarding_step.payload = {
        monthly_income: {
          id: this.inputs.monthly_income.id,
          description: this.inputs.monthly_income.descripcion,
        },
        monthly_investment: {
          id: this.inputs.monthly_investment.id,
          description: this.inputs.monthly_investment.descripcion,
        },
        operations_number: {
          id: this.inputs.operations_per_month.id,
          description: this.inputs.operations_per_month.descripcion,
        },
        source_of_income: {
          id: this.inputs.income_source.id,
          description: this.inputs.income_source.descripcion,
        },
        source_of_resources: {
          id: this.inputs.resources_origin.id,
          description: this.inputs.resources_origin.descripcion,
        },
        agreements: this.user_agreements,
        deposits_greater_than_21k: this.are_deposits_greater_than_21k,
      };
      if (this.exists_step) {
        delete this.on_boarding_step.customer_id;
        await this.updateOnBoardingStepCommand.execute(this.on_boarding_step);
      } else {
        await this.createOnBoardingStepCommand.execute(this.on_boarding_step);
      }
    } catch {
      this.messageNotifier.showErrorNotification(
        'Ha ocurrido un error al guardar tu información, inténtalo nuevamente',
      );
      this.is_loading = false;
      return false;
    }
    this.is_loading = false;
    return true;
  }

  initialize = async () => {
    const investment_providers = await this.searchInvestmentProvidersQuery.execute();
    this.setInvestmentProviderId(investment_providers);

    this.incomes = await this.searchIncomesQuery.execute();

    this.investments = await this.searchInvestmentsQuery.execute();

    this.operations = await this.SearchOperationsQuery.execute();

    this.sources = await this.searchSourcesQuery.execute();

    this.origins = await this.searchOriginsQuery.execute();

    this.agreements = await this.searchAgreementsAsyncQuery.execute();

    const steps = await this.searchOnBoardingStepsQuery.execute(this.investment_provider_id);

    await this.verifyStep(steps);

    await this.setUserAgreements();

    this.is_loading = false;
  }
}
